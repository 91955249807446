.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Tos-Text h1,h2,p,li {
  font-size: x-small;
  text-justify: auto;
}

.Tos-Text p {
  text-align: justify;
}

.Tos-Text li {
  text-align: left;
}

.RAText {
  text-align: left;
}

.ArtistSmall {
  text-align: left;
}

.CardTitle {
  background-color: #282c3430;
  color: #9183be;
}

.CardTitle_dark {
  background-color: #383838;
  color: #978ac1;
}

.IndicatorArtistsH {
  background-color: #b3b5e9;
  color: #000000;
}

.IndicatorSubText {
  font-size: 0.6em;
}

.IndicatorArtists {
  background-color: #b3b5e9;
  color: #000000;
}

.IndicatorAlbumsH {
  background-color: #FFE6B1;
  color:  #000000;
}

.IndicatorAlbums {
  background-color: #FFE6B1;
  color:  #000000;
}

.IndicatorTracksH {
  background-color: #D98E7E;
  color: #000000;
}

.IndicatorTracks {
  background-color: #D98E7E;
  color: #000000;
}

.IndicatorTimeH {
  background-color: #8dd97e;
  color: #000000;
}

.IndicatorTime {
  background-color: #8dd97e;
  color: #000000;
}
.IndicatorDayH {
  background-color: silver;
  color: #000000;
}

.IndicatorDay {
  background-color: silver;
  color: #000000;
}
.IndicatorYearH {
  background-color: #FFE4C4;
  color: #000000;
}

.IndicatorYear {
  background-color: #FFE4C4;
  color: #000000;
}

.IndicatorLengthH, .IndicatorLength {
  background-color: #9AFF9A;
  color: #000000;
}

.TopAlbum, .ListenDuration {
  min-width: 200px;
}

.ListenDurationRank{
  min-width: 7px;
  height:100px;
  align-items: center;
}

.RankBar {
  width:100px;
  height:30px;
  transform-origin: 0px 0px;
  transform: rotate(90deg);
}

.ValueStat {
  width:  100px;
  height: 100px;
}

.TitleFavNormal {
  position: relative;
  left: 132px;
  top: 105px;
}

.TrackMiniImage {
  width: 36px;
}

.TrackMediumImage {
  width: "512px";
}

.SwiperImage {
  object-fit: contain;
}


.AccordionMonthHeader {
  background-color: #d1d1d1,
}

.BlueText {
  color: #1976d2;
}

.LoggedOutTrackSwipe {
  max-width: 100px;
  height: 180px;
}



.swiper {
  width: 100px;
  height: 200px;
}

@media screen and (min-width:350px)   {
  .swiper
  {
    width: 320px;
    height: 300px;
  }
}
@media screen and  (min-width:600px)   {
  .swiper
  {
    width: 550px;
    height: 300px;
  }
}

@media screen and  (min-width:1000px)   {
  .swiper
  {
    width: 1000px;
    height: 300px;
  }
}


.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}